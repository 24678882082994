* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: KS-extrabold;
  src: url('../src/assets/fonts/Kumbh_Sans/KumbhSans-ExtraBold.ttf');
}
@font-face {
  font-family: KS-bold;
  src: url('../src/assets/fonts/Kumbh_Sans/KumbhSans-Bold.ttf');
}
@font-face {
  font-family: KS-semibold;
  src: url('../src/assets/fonts/Kumbh_Sans/KumbhSans-SemiBold.ttf');
}
@font-face {
  font-family: KS-medium;
  src: url('../src/assets/fonts/Kumbh_Sans/KumbhSans-Medium.ttf');
}
@font-face {
  font-family: KS-regular;
  src: url('../src/assets/fonts/Kumbh_Sans/KumbhSans-Regular.ttf');
}


h1,
h2 {
  font-family: KS-extrabold !important;
}
h3,
h4 {
  font-family: KS-bold !important;
}
h5 {
  font-family: KS-semibold !important;
}
h6 {
  font-family: KS-medium !important;
}
p,
a {
  font-family: KS-regular !important;
}


.btn-green {
  background-color: #316A31 !important;
  font-family: KS-semibold !important;
  color: #fff !important;
  font-size: 15px !important;
  min-height: 50px;
}
input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='date'],
input[type='month'],
input[type='tel'],
input[type='url'],
select {
  min-height: 50px;
  font: 15px KS-regular !important;
}


.check-in-area{
  background-color: #F3FFF3;
  min-height: 100vh;
  width:100%
}
.showcase-area{
  background-color: #316A31;
  color:#fff;
  padding:20px;
  border-radius: 10px;
}
.scan-button{
  background: rgba(244, 247, 255, 0.79);
  box-shadow: 0px 4px 4px rgba(104, 162, 251, 0.24);
  border-radius: 10px;
  width:280px;
  height:260px;
  color:#316A31;
  cursor:pointer;
  padding: 30px 10px;
}
.qr-camera-area{
  background-color: #FFFFFF99;
  height: 300px;
  width:300px;
  margin:10px auto;
  display:flex;
  align-items:center;
  justify-content:center;
  position:relative;
}
.qr-camera-area:after{
  content:"";
  background-color: red;
  height: 2px;
  width:100%;
  position:absolute;
  animation: 3s scanningAnim ease-in-out infinite alternate;
}


.scanned-response .response-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.scanned-response small {
  font: 12px KS-regular;
  color: #172B4D !important;
  margin-top: 20px;
}
.scanned-response p {
  font: 15px KS-bold;
  color: #1B365D !important;
  /* margin-bottom: 7px; */
}
.scanned-response hr {
  padding: 0;
  margin: 0;
}

@keyframes scanningAnim {
  0% {
    margin-top: -100%;
  }

  100% {
    margin-top: 100%;
  }
}